$gh_orange: #e86956;
$gh_orange_darker: #e86f61;
$gh_orange_lighter: #e87157;

$gh_blue: #50d48e;
$gh_blue_darker: #15161e;

$blue: $gh_blue;
$blue_darker: $gh_blue_darker;

$gh_green: #76B82A !default;

$green: $gh_orange !default;
$green_darker: $gh_orange_darker !default;
$green_lighter: $gh_orange_lighter !default;

$teal: $gh_green !default;
$teal_darker: $gh_orange_darker !default;
$teal_lighter: $gh_orange_lighter !default;

$black: $gh_blue !default;
$black_darker: $gh_blue_darker !default;
$black_lighter: #575d63 !default;
$dark: #2c3e50;
