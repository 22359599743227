@import '_variable';

body {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    color: #2c3e50;
}

.linkbox_large, .linkbox_left, .linkbox_right, .Linkbox_rechts, .Linkbox_links, .Linkbox_gross {
    padding: 5px 15px 10px;
    border: 1px solid $dark;
    margin: 10px 0 10px 0;
    width: 100%;
    display: block;
    border-top: 30px solid $dark;
    color: $dark;

    &::before {
        content: 'Mehr zum Thema';
        margin-top: -31px;
        display: inherit;
        color: #fff;
        font-size: 15px;
        margin-bottom: 15px;
    }
}

.linkbox_left, .linkbox_right, .Linkbox_rechts, .Linkbox_links {
    width: 50%;
    padding: 5px 15px 10px 10px;
    margin: 10px 20px 20px 0;
}

.linkbox_left, .Linksbox_links {
    float: left;
}

.linkbox_right, .Linkbox_rechts {
    float: right;
}

img.inline_image {
    cursor: pointer;

    &.left {
        float: left;
        margin: 0 28px 16px 0;
    }

    &.right {
        float: right;
        margin: 0 0 16px 28px;
    }
}

p {
    font-size: 16px;
    line-height: 26px;
}

h2 {
    font-weight: 400;
    margin-top: 50px;
    font-size: 26px;
}

b, strong {
    font-weight: 700;
}

.social-media-icon {
    width: 28px;
    height: auto;
    margin: 10px 5px;
}
